import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Coctelescon from "../../components/Sabores/CoctelesconEB";
import CarruselCompra from "../../components/CarruselCompra";
import BribeRecetas from "../../components/BribeRecetas";
import CarruselSabores from "../../components/CarruselSabores";
import Seo from "../../components/Seo";

function exoticberry() {
  const { strapiTag } = useStaticQuery(graphql`
    query {
      strapiTag(name: { eq: "Exotic Berry" }) {
        articles {
          id
          slug
          title
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Exotic Berry",
          metaDescription:
            "El sabor de Boone’s Exotic Berry, es una refrescante mezcla donde se fusionan los dulces sabores de las fresas, zarzamoras y frambuesas",
        }}
      />
      <header>
        <StaticImage
          src="../../assets/images/sabores/exoticberry/EB-BANNER.png"
          alt="Boones Exotic Berry"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="container relative -mt-5 mb-20 md:-mt-28">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div className="max-w-xl space-x-4 space-y-2 rounded-xl bg-white p-5 drop-shadow-xl md:h-56">
              <h1 className="text-center capitalize text-exoticberry">
                Tu lado más exótico
              </h1>
              <p className="max-w-prose font-light">
                El sabor de Boone’s Exotic Berry, es una refrescante mezcla
                donde se fusionan los dulces sabores de las fresas, zarzamoras y
                frambuesas.
              </p>
              <p className="max-w-prose font-light">
                Ya sea para las pláticas interminables hasta medianoche o
                simplemente un momento para ti, con Boone’s Exotic Berry es
                #ImposibleEquivocarse.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div>
          <StaticImage
            src="../../assets/images/sabores/exoticberry/info-exoticberry-desktop.png"
            alt="Boones Exotic Berry"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </div>
        <div className="mt-4">
          <Coctelescon articles={strapiTag.articles} />
        </div>
        <div className="mt-16">
          <CarruselCompra />
        </div>
        <div className="mt-16">
          <BribeRecetas />
        </div>
        <div className="mt-16">
          <CarruselSabores />
        </div>
      </main>
    </Layout>
  );
}
export default exoticberry;
